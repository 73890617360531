<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    floating
    stateless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? '' : null"
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar size="48" color="secondary">
          <v-icon size="24" class="white--text">
            {{ formIcon }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">
          {{ formTitle }}
        </div>
      </div>
      <v-btn icon x-small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>
    <form>
      <validation-observer ref="observer">
        <!-- Rol -->

        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Rol" rules="required">
          <v-select
            v-model="userData.profile_id"
            label="Rol"
            :items="roleOptions"
            class="rounded-0"
            :dark="isDark"
            :menu-props="{ bottom: true, offsetY: true }"
            :error-messages="errors"
            filled
            hide-details
            return-object
          ></v-select>
        </validation-provider>

        <!-- Durum -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Durum" rules="required">
          <v-select
            v-model="userData.aktif"
            label="Durum"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            class="rounded-0"
            :dark="isDark"
            :menu-props="{ bottom: true, offsetY: true }"
            :error-messages="errors"
            filled
            hide-details
            return-object
          ></v-select>
        </validation-provider>

        <!-- Ad -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Ad" rules="required|max:500">
          <v-text-field
            v-model="userData.ad"
            filled
            :dark="isDark"
            label="Ad"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>

        <!-- Soyad -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Soyad" rules="required|max:500">
          <v-text-field
            v-model="userData.soyad"
            filled
            :dark="isDark"
            label="Soyad"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>

        <!-- Kullanıcı Adı -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Kullanıcı Adı" rules="required|max:500">
          <v-text-field
            v-model="userData.username"
            filled
            :dark="isDark"
            label="Kullanıcı Kodu"
            autocomplete="username"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>

        <!-- Şifre -->
        <validation-provider
          v-if="userType === 'add'"
          v-slot="{ errors }"
          tag="div"
          mode="eager"
          name="Şifre"
          rules="required"
        >
          <v-text-field
            v-model="userData.sifre"
            filled
            :dark="isDark"
            autocomplete="new-password"
            label="Şifre"
            :type="show ? 'text' : 'password'"
            class="rounded-0"
            hide-details
            :error-messages="errors"
          >
            <template v-slot:append>
              <v-btn text plain x-small tabindex="-1" @click="show = !show">
                <v-icon>
                  {{ show ? icons.mdiEye : icons.mdiEyeOff }}
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </validation-provider>

        <!-- E-Posta -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="E-Posta" rules="required|email">
          <v-text-field
            v-model="userData.mail"
            filled
            :dark="isDark"
            type="email"
            label="E-Posta"
            hide-details
            class="rounded-0"
            :error-messages="errors"
          ></v-text-field>
        </validation-provider>

        <!-- Telefon -->
        <vue-tel-input-vuetify
          v-model="userData.gsm"
          label="Telefon"
          placeholder=""
          wrapper-classes="flex-row-reverse telInput_customize"
          hide-details
          :input-options="{ showDialCode: true, tabindex: 0 }"
          default-country="tr"
          mode="international"
          filled
          :dark="isDark"
        ></vue-tel-input-vuetify>

        <!-- Eğer Plasiyer İse -->
        <div v-if="userData.profile_id.type === '3'">
          <!-- Satış Temsilcisi -->
          <v-text-field
            v-model="userData.saldept"
            filled
            :dark="isDark"
            label="Satış Temsilcisi"
            hide-details
            class="rounded-0"
          ></v-text-field>

          <!-- Kontakt Numarası -->

          <v-text-field
            v-model="userData.contact_num"
            filled
            :dark="isDark"
            label="Kontakt Numarası"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </div>
      </validation-observer>
    </form>
    <template v-slot:append>
      <div class="pa-3">
        <!-- <v-btn

          block
          text
          small
          rounded
          plain
          class="mb-3"
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          Vazgeç
        </v-btn> -->
        <v-btn x-large tile block :loading="loading" color="secondary" @click="submit">
          {{ formButtonText }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiEye, mdiEyeOff, mdiPencil, mdiPlusThick } from '@mdi/js'
import Vue from 'vue'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    customerGroupOptions: {
      type: Array,
      required: true,
    },
    pricelistOptions: {
      type: Array,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
    userItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isDark } = useAppConfig()

    return {
      isDark,

      icons: {
        mdiClose,
        mdiEyeOff,
        mdiEye,
        mdiPlusThick,
        mdiPencil,
      },
    }
  },
  data: () => ({
    durum: {
      text: '',
      value: '1',
    },
    items: [],
    isLoading: false,
    search: null,
    show: false,
    loading: false,
    userData: {
      method: 'insertMember',
      profile_id: '',
      ad: '',
      soyad: '',
      username: '',
      mail: '',
      gsm: '',
      sifre: '',
      sifret: '',
      aktif: '',
      customer_name: '',
      cari_kod: '',
      pricelist: '',
      saldept: '',
      contact_num: '',
      cust_group: '',
    },
    defaultItem: {
      method: 'insertMember',
      profile_id: '',
      ad: '',
      soyad: '',
      username: '',
      mail: '',
      gsm: '',
      sifre: '',
      sifret: '',
      aktif: '',
      customer_name: '',
      cari_kod: '',
      pricelist: '',
      saldept: '',
      contact_num: '',
      cust_group: '',
    },
  }),
  computed: {
    formTitle() {
      return this.userType === 'add' ? 'Yeni Yönetici Ekle' : 'Yönetici Düzenle'
    },
    formIcon() {
      return this.userType === 'add' ? this.icons.mdiPlusThick : this.icons.mdiPencil
    },
    formButtonText() {
      return this.userType === 'add' ? 'Kaydet' : 'Güncelle'
    },
  },

  watch: {
    isAddNewUserSidebarActive(val) {
      if (val && this.userType === 'edit') {
        this.$nextTick(() => {
          this.userData = {
            method: 'insertMember',
            mode2: 'memberUpdate',
            uid: this.userItem?.uid,
            ad: this.userItem?.adsoyad.split(' ')[0],
            soyad: this.userItem?.adsoyad.split(' ')[1],
            username: this.userItem?.username,
            mail: this.userItem?.mail,
            gsm: this.userItem?.gsm,
            aktif: this.userItem?.aktif,
            customer_name: '',
            cari_kod: this.userItem?.cari_kod,
            pricelist: this.userItem?.pricelist,
            saldept: '',
            contact_num: '',
            cust_group: '',
            profile_id: this.userItem?.profil,
          }
          debugger
          console.log(this.userItem)
        })
      }
      val || this.close()
    },
  },
  methods: {
    async submit() {
      debugger
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.loading = false

        Vue.swal({
          title: 'Hata',
          text: 'Lütfen kırımızı alanları doldurun',
          icon: 'error',
          timer: 2000,
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })

        return
      }

      // Gönderilecek verilerin düzenlenmesi
      this.userData.profile_id = this.userData.profile_id
      this.userData.sifret = this.userData.sifre
      this.userData.aktif = this.durum.value

      this.$store.dispatch('app-admin/addAdmin', this.userData).then(response => {
        if (response.error) {
          Vue.swal({
            title: 'Hata',
            text: response.msg,
            icon: 'error',
            timer: 2000,
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        } else {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-user-sidebar-active', false)

          Vue.swal({
            title: 'Kaydedildi',
            text: 'İşlem başarılı',
            icon: 'error',
            timer: 2000,
            background: '#56CA00',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
        this.loading = false
      })
    },
    close() {
      this.$refs.observer.reset()
      this.$nextTick(() => {
        this.userData = { ...this.defaultItem }
      })
    },
  },
}
</script>
